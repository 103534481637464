import React from 'react';

import { FieldValidator, useField } from 'formik';

import { convertSecondsToHHMM, convertHHMMToSeconds } from 'utils/date';
import { Form } from 'semantic';

interface Props {
  name: string;
  label?: string;
  validate?: FieldValidator;
  /**
   * if true, the error label will not be shown below the input if the field is not valid. Note that the field will still
   */
  hideErrorLabel?: boolean;
}

const TimeField: React.FC<Props> = ({
  name,
  label,
  validate,
  hideErrorLabel,
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <Form.Input
      type="time"
      label={label}
      name={name}
      value={field.value ? convertSecondsToHHMM(field.value) : ''}
      onChange={(e, { value }) => {
        helpers.setValue(convertHHMMToSeconds(value), true);
      }}
      error={
        meta.touched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
    />
  );
};

export default TimeField;
